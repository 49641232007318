import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';

const getTimeseriesData = (data, metric) => {
    if (data && data.length > 0) {
        return (
            data.map(d => {
                return {
                    x: new Date(d.date),
                    y: d.amm[metric], 
                }    
            })
        );
    } else {
        return [];
    }
}

const highchartsConfig = (metrics = []) => {
    return {
        chart: {
            type: 'area',
            height: 250,
        },
        title: {
            text: 'Total XRP Pooled',
        },
        tooltip: {
            xDateFormat: '%Y-%m-%d',
            pointFormat: '{series.name}: <b>{point.y}</b>',
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        xAxis: {
            type: 'datetime',
            minTickInterval: 24 * 36e5, // 1 day
            labels: {
                align: 'left',
            },
            crosshair: true,
        },    
        yAxis: [
            {
                allowDecimals: false,
                labels: {
                    enabled: true,
                    style: {
                        color: Highcharts.getOptions().colors[0],
                    }
                },
                title: {
                    text: 'AMM Pools',
                    style: {
                        color: Highcharts.getOptions().colors[0],
                    }
                },
                opposite: true,
                gridLineWidth: 0,
            },
            {
                labels: {
                    enabled: true,
                    style: {
                        color: Highcharts.getOptions().colors[1],
                    }
                },
                title: {
                    text: 'XRP Pooled',
                    style: {
                        color: Highcharts.getOptions().colors[1],
                    }
                },
                gridLineColor: 'rgba(0, 0, 0, 0.07)',
            },
        ],
        plotOptions: {
            series: {
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    radius: 2,
                },
                fillOpacity: 0.5,
            },
        },
        series: [
            {
                name: 'AMM pools',
                id: 'amm_count',
                type: 'area',
                step: 'center',
                data: getTimeseriesData(metrics, 'amm_count'),
            },
            {
                yAxis: 1,
                name: 'XRP Pooled',
                id: 'xrp_locked',
                type: 'area',
                step: 'center',
                data: getTimeseriesData(metrics, 'xrp_locked'),
            },
        ],
    }
}

const AMMFactChart = (props) => {
    const { metrics } = props;

    useEffect(() => {
        HC_exporting(Highcharts);
    }, [metrics]);

    return (
        <Card className='shadow-sm'>
            <Card.Body>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={highchartsConfig(metrics)}
                />
            </Card.Body>
        </Card>
    );
}

AMMFactChart.propTypes = {
    metrics: PropTypes.object.isRequired,
}

export default AMMFactChart;